import RLMSDetails from "./rates/RLMSDetails";
import RSDetails from "./rates/RSDetails";

import residentialService from "./rates/residentialService";
import residentialLoadManagementService from "./rates/residentialLoadManagementService";

//OBS: For the rates data, the max and min ALWAYS need to have 12 elements.

const rates = {
  "RS": {
    id: "RS",
    title: ["Residential Service (RS)"],
    subTitle: [""],
    data: residentialService,
    detailsComponent: RSDetails,
  },
  "RLMS": {
    id: "RLM",
    title: ["Residential Load Management (RLM)"],
    subTitle: [""],
    data: residentialLoadManagementService,
    detailsComponent: RLMSDetails,
  }
};

export default rates;
