import React, { useState, useContext } from 'react';
import UserPrefsContext from '../../../context/UserPrefs/UserPrefsContext';
import PropTypes from 'prop-types';
import './PricePanels.scss';

import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { FormatAsDollars } from './../../../utils/Helpers/Format';

import { FormattedMessage, useIntl } from 'react-intl';

const PricePanels = ({ paymentDetails }) => {
  const intl = useIntl();
  const userPrefs = useContext(UserPrefsContext);
  const purchaseMethodTabs = {
    cash: '1',
    loan: '2',
    lease: '3',
  };

  const [activeTab, setTab] = useState(
    purchaseMethodTabs[userPrefs.get('purchaseMethod')]
  );

  const offMsrp =  paymentDetails.msrp - paymentDetails.afterIncentives;

  const updatePurchaseMethod = (purchaseMethodValue) => {
    userPrefs.set({ purchaseMethod: purchaseMethodValue });
    switch (purchaseMethodValue) {
      case 'cash':
        setTab('1');
        break;
      case 'loan':
        setTab('2');
        break;
      case 'lease':
        setTab('3');
        break;
      default:
        setTab('1');
    }
  };

  if (!paymentDetails) return null;

  return (
    <>
      <div className="PricePanels">
        <Nav pills className="nav-fill">
          <NavItem>
            <NavLink
              className={
                userPrefs.get('purchaseMethod') === 'cash' ? 'active' : ''
              }
              onClick={() => updatePurchaseMethod('cash')}
              onKeyPress={() => userPrefs.set({ purchaseMethod: 'cash' })}
              tabIndex="0"
            >
              <span>
                <FormattedMessage
                  id="pricePanels.cash"
                  defaultMessage="Cash"
                  description="Price Panel Cash"
                />
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={
                userPrefs.get('purchaseMethod') === 'loan' ? 'active' : ''
              }
              onClick={() => updatePurchaseMethod('loan')}
              onKeyPress={() => userPrefs.set({ purchaseMethod: 'loan' })}
              tabIndex="0"
            >
              <span>
                <FormattedMessage
                  id="pricePanels.loan"
                  defaultMessage="Loan"
                  description="Price Panel Loan"
                />
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={
                userPrefs.get('purchaseMethod') === 'lease' ? 'active' : ''
              }
              onClick={() => updatePurchaseMethod('lease')}
              onKeyPress={() => userPrefs.set({ purchaseMethod: 'lease' })}
              tabIndex="0"
            >
              <span>
                <FormattedMessage
                  id="pricePanels.lease"
                  defaultMessage="Lease"
                  description="Price Panel Lease"
                />
              </span>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <div className="row">
              <div className="col-sm-12">
                <div className="PanelItem text-center">
                  <div className="PanelItemTop">
                    <p className="RenderItemTitle uppercase">
                      <FormattedMessage
                        id="vehicle.afterIncentives"
                        defaultMessage="After Incentives"
                        description="Price Panel After Incentives"
                      />
                    </p>
                    <p className="h1">
                      {FormatAsDollars(paymentDetails.afterIncentives)}
                    </p>
                    <p className="RenderItemTitle">
                      <FormattedMessage
                        id="vehicle.plusTax"
                        defaultMessage="plus tax"
                        description="plus tax label"
                      />
                    </p>
                    <p className="RenderItemTitle">
                      <FormattedMessage
                        id="vehicle.offMSRP"
                        defaultMessage="{msrp} off MSRP"
                        description="off MSRP label"
                        values={{ msrp: FormatAsDollars(offMsrp) }}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane tabId="2">
            <div className="row">
              <div className="col-sm-12">
                <div className="PanelItem text-center">
                  <div className="PanelItemTop">
                    <p className="RenderItemTitle">
                      <FormattedMessage
                        id="vehicle.afterIncentives"
                        defaultMessage="After Incentives"
                        description="Price Panel After Incentives"
                      />
                    </p>
                    <p className="h1">
                      {FormatAsDollars(paymentDetails.loanAmountPerMonth) +
                        intl.formatMessage({
                          id: 'pricePanels.perMonth',
                          defaultMessage: ' / mo',
                        })}
                    </p>
                    <p className="RenderItemTitle">
                      <FormattedMessage
                        id="vehicle.downPaymentMonth"
                        defaultMessage="for {months} months"
                        description="for months label"
                        values={{ months: 60 }}
                      />
                    </p>
                    <p className="RenderItemTitle">
                      <FormattedMessage
                        id="vehicle.downPayment"
                        defaultMessage="+ {downPayment} down payment"
                        description="down payment label"
                        values={{
                          downPayment: FormatAsDollars(
                            paymentDetails.downPayment
                          ),
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane tabId="3">
            <div className="row">
              <div className="col-sm-12">
                <div className="PanelItem text-center">
                  <div className="PanelItemTop">
                    <p className="RenderItemTitle">
                      <FormattedMessage
                        id="vehicle.afterIncentives"
                        defaultMessage="After Incentives"
                        description="Price Panel After Incentives"
                      />
                    </p>
                    <p className="h1">
                      {FormatAsDollars(paymentDetails.leaseAmountPerMonth) +
                        intl.formatMessage({
                          id: 'pricePanels.perMonth',
                          defaultMessage: '/month',
                        })}
                    </p>

                    <p className="RenderItemTitle">
                      <FormattedMessage
                        id="vehicle.downPaymentMonth"
                        defaultMessage="for {months} months"
                        description="for months label"
                        values={{ months: 36 }}
                      />
                    </p>
                    <p className="RenderItemTitle">
                      <FormattedMessage
                        id="vehicle.downPayment"
                        defaultMessage="+ {downPayment} down payment"
                        description="down payment label"
                        values={{
                          downPayment: FormatAsDollars(
                            paymentDetails.leaseFirstPayment
                          ),
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
        </TabContent>
      </div>
    </>
  );
};

export default PricePanels;

PricePanels.propTypes = {
  car: PropTypes.object,
  paymentDetails: PropTypes.object,
};
