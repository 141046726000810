import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
} from 'reactstrap';
// import planet from '../../../assets/images/icons/Planet.svg';
import logo from '../../../assets/images/PSEG-Logo.svg';
import './Header.scss';
import { FormattedMessage } from 'react-intl';

const Header = ({
  page,
  zipcode,
  activePage,
  userLocation,
  language,
  changeLanguage,
}) => {
  const [collapse, setCollapse] = useState(false);

  const isHomepage =
    window.location.pathname === '/' || window.location.pathname === null;

  // const mobile = window.innerWidth < 720;

  // const onChangeLanguage = (e) => {
  //   changeLanguage(e);
  // };
  return (
    <div className="Header">
      <div className="container-fluid" style={{ padding: 0 }}>
        <Navbar expand="lg">
          <div
            style={{
              paddingTop: '0.75rem',
              paddingBottom: '0.75rem',
            }}
          >
            <Link to="/" className={isHomepage ? 'active' : ''}>
              <img
                src={logo}
                className="homepagelogo"
                alt="{process.env.REACT_APP_FULL_COMPANY_NAME}"
              />
            </Link>
          </div>
          <NavbarToggler onClick={() => setCollapse(!collapse)} />
          <Collapse isOpen={collapse} navbar>
            <Nav navbar>
              <NavItem>
                <Link
                  to="/faq"
                  className={activePage === 'faq' ? 'active' : ''}
                >
                  <span className="mobile-header-item">
                    <FormattedMessage
                      id="header.faq"
                      defaultMessage="EV 101"
                      description="FAQ HEader"
                    />
                  </span>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to="/vehicles"
                  className={activePage === 'vehicles' ? 'active' : ''}
                >
                  <span className="mobile-header-item">
                    <FormattedMessage
                      id="header.electricVehicles"
                      defaultMessage="Browse EVs"
                      description="Shop Electric Header"
                    />
                  </span>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to="/compare-vehicles"
                  className={activePage === 'compare-vehicles' ? 'active' : ''}
                >
                  <span className="mobile-header-item">
                    <FormattedMessage
                      id="header.compareVehicles"
                      defaultMessage="Compare Vehicles"
                      description="Compare Vehicles Header"
                    />
                  </span>
                </Link>
              </NavItem>
              <NavItem>
                <NavLink
                  title={page === 'rates' ? 'Active Page' : null}
                  to="/rates"
                >
                  <span className="mobile-header-item">
                    <FormattedMessage
                      id="header.rates"
                      defaultMessage="Compare Rates"
                      description="Rates Header"
                    />
                  </span>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  title={page === 'incentives' ? 'Active Page' : null}
                  to="/incentives"
                >
                  <span className="mobile-header-item">
                    <FormattedMessage
                      id="eader.incentive"
                      defaultMessage="Incentives"
                      description="Incentives Header"
                    />
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  title={page === 'charging' ? 'Active Page' : null}
                  to="/charging-stations"
                >
                  <span className="mobile-header-item">
                    <FormattedMessage
                      id="header.chargingStations"
                      defaultMessage="Charging StationsS"
                      description="Charging Stations Header"
                    />
                  </span>
                </NavLink>
              </NavItem>
              {/* {mobile ? (
                <NavItem>
                  <span
                    className={
                      language === 'EN'
                        ? 'language-active mobile-header-item'
                        : 'mobile-header-item'
                    }
                    style={{ marginRight: '10px' }}
                    onClick={() => onChangeLanguage('EN')}
                  >
                    <FormattedMessage
                      id="header.english"
                      defaultMessage="English"
                      description="English Header"
                    />
                  </span>
                  <span className="mobile-header-item">{'|'}</span>
                  <span
                    className={
                      language === 'ES'
                        ? 'language-active mobile-header-item'
                        : 'mobile-header-item'
                    }
                    style={{ marginLeft: '10px' }}
                    onClick={() => onChangeLanguage('ES')}
                  >
                    <FormattedMessage
                      id="header.spanish"
                      defaultMessage="Spanish"
                      description="Spanish Header"
                    />
                  </span>
                </NavItem>
              ) : null} */}
            </Nav>
          </Collapse>
          {/* {!mobile ? (
            <ul style={{ padding: '0', marginTop: '15px' }}>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret aria-label="Toggle Dropdown">
                  <span>
                    <img
                      style={{ marginBottom: '7px' }}
                      src={planet}
                      alt="planet"
                    ></img>
                  </span>
                </DropdownToggle>
                <DropdownMenu
                  style={{ color: '#142C41', backgroundColor: '#ffffff' }}
                >
                  <DropdownItem
                    className={language === 'EN' ? 'item active' : 'off item'}
                    onClick={() => onChangeLanguage('EN')}
                  >
                    EN
                  </DropdownItem>
                  <DropdownItem
                    className={language === 'ES' ? 'item active' : 'off item'}
                    onClick={() => onChangeLanguage('ES')}
                  >
                    ES
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </ul>
          ) : null} */}
        </Navbar>
      </div>
    </div>
  );
};

export default Header;

Header.propTypes = {
  page: PropTypes.string,
};
