import React from 'react';
import PropTypes from 'prop-types';
import LinkCard from '../../LinkCard/LinkCard';
import homepageBanner from '../../../assets/images/homepage-title.svg';
import homepageBannerMobile from '../../../assets/images/homepage-title-mobile.svg';
import { FormattedMessage } from 'react-intl';


const HomepageBigPromise = () => {
  const mobile = window.innerWidth < 720;
  return (
    <section className="white">
      <div className="homepage-jumbotron">
        <img
          src={mobile ? homepageBannerMobile : homepageBanner}
          alt="homepage-banner"
        />
        <p className="title-banner">
          <FormattedMessage
            id="homepage.banner.title"
            defaultMessage="YEstimate and compare costs, savings, incentives & more."
            description="YEstimate and compare costs, savings, incentives & more. "
          />
        </p>
      </div>
      <div className="homepage-banner"></div>
      <div className="link-card-container">
        <LinkCard type="BROWSE_VEHICLES" />
        <LinkCard type="COMPARE_VEHICLES" />
        <LinkCard type="DISCOVER_INCENTIVES" />
        <LinkCard type="COMPARE_RATES" />
        <LinkCard type="FIND_CHARGING" />
      </div>
    </section>
  );
};

export default HomepageBigPromise;

HomepageBigPromise.propTypes = {
  homePageBannerImage: PropTypes.string,
  userLocation: PropTypes.string,
};
