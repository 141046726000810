import { gql, GraphQLClient } from 'graphql-request';

const fetchFaqsGraphQl = async (token) => {
  let endpoint = process.env.REACT_APP_AMPLIFY_STAGING_ENDPOINT;
  let apiKey = process.env.REACT_APP_AMPLIFY_STAGING_API_KEY;

  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    endpoint = process.env.REACT_APP_AMPLIFY_PRODUCTION_ENDPOINT;
    apiKey = process.env.REACT_APP_AMPLIFY_PRODUCTION_API_KEY;
  }

  const graphQLClient = new GraphQLClient(endpoint, {
    headers: {
      'X-Api-Key': apiKey,
    },
  });

  let query = gql`
    {
      listPsegFAQS {
        items {
          page
          section
          articleTitle
          articleBody
        }
        nextToken
      }
    }
  `;

  if (token) {
    query = gql`{  
      listPsegFAQS (nextToken: "${token}") {
      items {          
        page
        section
        articleTitle
        articleBody
      }        
      nextToken
    }}`;
  }

  const data = await graphQLClient.request(query);
  return data;
};

export const fetchFaqs = async (params) => {
  const faqs = [];
  let token;
  while (token !== null) {
    let data = await fetchFaqsGraphQl(token);

    faqs.push(...data.listPsegFAQS.items);
    token = data.listPsegFAQS.nextToken;
  }
  return faqs;
};


export default fetchFaqs;
