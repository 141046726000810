import React from "react";

import "./styles.scss";

const RSDetails = () => {
  return (
    <div>
      <h2 className="title">Residential Service</h2>

      <h3>Rate Overview</h3>
      <p className="text">
        The Residential Service (RS) rate schedule is designed for residential customers receiving electric delivery service from Public Service Electric and Gas Company (PSE&G). Customers can choose to purchase their electric supply from a Third Party Supplier (TPS) or from PSE&G’s Basic Generation Service (BGS). The rate includes a monthly service charge and varying distribution charges based on usage and season.
      </p>

      <h3>How it Works</h3>
      <ul>
        <li>
          <strong>Service Charge: </strong>
          <p>A fixed monthly fee of $4.64 (or $4.95 with New Jersey Sales and Use Tax).</p>
        </li>
        <li>
          <strong>Distribution Charges: </strong>
          <p>Billed per kilowatt-hour (kWh) and vary by season and usage:</p>
          <ul>
            <li>
              <strong>October through May: </strong>
              <p>$0.033344 per kWh (up to 600 kWh), $0.033344 per kWh (over 600 kWh).</p>
            </li>
            <li>
              <strong>June through September: </strong>
              <p>$0.047308 per kWh (up to 600 kWh), $0.051129 per kWh (over 600 kWh).</p>
            </li>
            <li>
              <p>Charges include additional fees for infrastructure improvements and various public policy programs.</p>
            </li>
          </ul>
        </li>
        <li>
          <strong>Additional Charges: </strong>
          <p>Include societal benefits, non-utility generation, solar pilot recovery, green programs, tax adjustments, zero emission certificates, and conservation incentives, all combined in the billing.</p>
        </li>
      </ul>
    </div>
  );
};

export default RSDetails;