import React from 'react';
import PropTypes from 'prop-types';
import { TabContent, TabPane } from 'reactstrap';

import {
  ALL_STATIONS_TAB_ID,
  ROUTE_MAP_TAB_ID,
} from '../../../constants/mapTabs';
import ChargingMap from '../LocationMap/ChargingMap/ChargingMap';
import {  FormattedMessage } from 'react-intl';

import './TabbedMaps.scss';
import TravelRouteMap from '../../../components/LocationMap/TravelRouteMap/TravelRouteMap';


const TabbedMaps = ({
  chargingStationsFilterFn,
  electricVehicles,
  activeTabId,
  toggle,
  userLocation,
  zipcode,
  pspsEvents = [],
}) => {

  return (
    <div className="TabbedMaps">
      <div className="container tabbed-container">
        <div className="tabbed-maps-panel">
          
          <TabContent activeTab={activeTabId}>
            {process.env.REACT_APP_PAGES_CHARGING_ENABLED && (
              <TabPane tabId={ALL_STATIONS_TAB_ID}>
                <ChargingMap
                  userLocation={userLocation}
                  isVisible={activeTabId === ALL_STATIONS_TAB_ID}
                  zipcode={zipcode}
                  toggleWithHistory={toggle}
                />
              </TabPane>
            )}
            {process.env.REACT_APP_PAGES_MAP_ROUTE_ENABLED && (
              <TabPane tabId={ROUTE_MAP_TAB_ID}>
                <TravelRouteMap
                  onlyTotalRange
                  chargingStationsFilterFn={chargingStationsFilterFn}
                  electricVehicles={electricVehicles}
                  isVisible={activeTabId === ROUTE_MAP_TAB_ID}
                  canIgnoreLowPowerStations={false}
                  zipcodePlaceholder="Choose a location"
                  pspsEvents={pspsEvents}
                  showStationsByPowerLevel
                  showLegendCard={false}
                  hideStations
                  userLocation={userLocation}
                  btnStyles={{ width: '100%' }}
                  footerText=""
                />
              </TabPane>
            )}
          </TabContent>
        </div>
      </div>
      <p style={{ marginTop: '40px' }}>
        <FormattedMessage
          id="levelOneCharger"
          defaultMessage="Mileage ranges are based upon estimates provided by EV manufacturers. Your range may vary based upon climate, region, traffic, car model, and automobile conditions. Data from the Alternative Fuel Data Center may not reflect latest availability. Please confirm availability of charging stations before commencing your journey. Submit suspected missing charging stations {here}."
          description="Mileage ranges are based upon estimates provided by EV manufacturers. Your range may vary based upon climate, region, traffic, car model, and automobile conditions. Data from the Alternative Fuel Data Center may not reflect latest availability. Please confirm availability of charging stations before commencing your journey. Submit suspected missing charging stations {here}."
          values={{
            here: (
              <a 
                style={{fontWeight: 700, color: "#142C41", textDecoration: "underline"}}
                href="https://afdc.energy.gov/stations#/station/new"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
            ),
          }}
        />
      </p>
    </div>
  );
};

TabbedMaps.propTypes = {
  chargingStationsFilterFn: PropTypes.func,
  electricVehicles: PropTypes.array,
  oems: PropTypes.array,
  activeTabId: PropTypes.string,
  toggle: PropTypes.func,
  canIgnoreLowPowerStations: PropTypes.bool,
};

export default TabbedMaps;
