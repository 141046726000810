 import React, { useContext } from "react";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import { FormattedMessage, useIntl } from "react-intl";
import ToolTip from "../../../components/shared/ToolTip/ToolTip";

 
const EVFilterType = () => {
  const intl = useIntl()  
  const userPrefs = useContext(UserPrefsContext);

  const formFactorFilterData = userPrefs.get("vehicleFormFactorFilters");

  const updateFormFactorFilter = (e, key) => {
    e.preventDefault();
    let newFilters = Object.assign({}, formFactorFilterData);
    if (key === "suv") {
      newFilters['crossover'] = !newFilters['crossover'];
    }
    newFilters[key] = !newFilters[key];
    userPrefs.set({
      vehicleFormFactorFilters: newFilters,
    });
  };

 const renderedFormFactorFilters = Object.keys(formFactorFilterData).map(
    (filterKey, i) => {
      const filterName =
        filterKey === 'suv'
          ? 'SUV/Crossover'
          : `${filterKey.charAt(0).toUpperCase()}${filterKey.substr(1)}`;
       

          if (filterKey === 'crossover') {
            return null;
          }
      return filterName === 'SUV/Crossover' ? (
        <button
          className={
            formFactorFilterData[filterKey] ? 'btn-wide active' : 'btn-wide'
          }
          key={i}
          aria-pressed={!!formFactorFilterData[filterKey]}
          onClick={(e) => updateFormFactorFilter(e, filterKey)}
        >
          {filterName}
        </button>
      ) : (
        <button
          className={formFactorFilterData[filterKey] ? 'btn active' : 'btn'}
          key={i}
          aria-pressed={!!formFactorFilterData[filterKey]}
          onClick={(e) => updateFormFactorFilter(e, filterKey)}
        >
          {filterName}
        </button>
      );
    }
  );

  return (
    <div className="form-group">
        <span className="label-style">
            <FormattedMessage
                id="evfilter.type"
                defaultMessage="Type"
                description="Type"
            />
        </span>
        <ToolTip
            message={
                intl.formatMessage
                ? intl.formatMessage({
                    id: "vehicleType",
                    defaultMessage:
                    "Select all vehicle body types you are interested in.",
                })
                : "Select all vehicle body types you are interested in."
            }
            id="fuel_tooltip"
        />
        <div className="btn-grid-container">{renderedFormFactorFilters}</div>
    </div>)
}

export default EVFilterType;
